.page-navigation {
  .menu-reference {
    &.menu-reference--loyalty,
    &.menu-reference--signin,
    &.menu-reference--account {
      @media #{$medium-up} {
        display: none;
      }
    }
  }
}

nav.page-navigation {
  .page-navigation__menu {
    text-align: unset;
  }
  .menu-ref__title {
    font-family: $DBHeavent;
    font-size: 20px;
    letter-spacing: 0.05em;
    a {
      font-family: $AkzidenzGrotesk;
      font-size: 14px;
    }
  }
}

.page-utilities {
  .user-loyalty-state {
    bottom: 0;
    position: absolute;
    text-align: $rdirection;
    min-width: 375px;
  }
  .page-utilities__account {
    .page-utilities__signin-text {
      cursor: pointer;
    }
  }
}

.page-utilities-loyalty-elc-nodeblock {
  float: $ldirection;
}

.ff-results {
  .ff-results {
    &__matches-sub-headline,
    &__regimen-steps {
      display: none;
    }
  }
}

.trust-mark-elc-nodeblock {
  float: $ldirection;
  .trust_mark_header {
    position: relative;
    width: 80px;
    height: 80px;
    .trust_mark_header__link {
      position: absolute;
      width: 70px;
      height: 80px;
      top: -12px;
      background-image: url('/media/images/global/lo3.png');
      background-repeat: no-repeat;
      text-indent: -9999px;
    }
  }
}

.page-branding__logo {
  float: $rdirection;
}

.section-re-nutriv,
#beauty_feed {
  .trust-mark-elc-nodeblock {
    .trust_mark_header {
      .trust_mark_header__link {
        background-image: url('/media/images/global/lo3-white.png');
      }
    }
  }
}

.page-utilities__account {
  @media #{$medium-up} {
    margin-top: 0px;
  }
}

.signin-overlay-wrapper {
  .sign-in-component__form--registration {
    .address {
      .field-container {
        margin-bottom: 20px;
        .form-item {
          margin-#{$rdirection}: 30px;
          display: inline-table;
        }
      }
    }
    .sign-in-component__birthday-wrapper {
      .form-item {
        select {
          min-width: 160px;
        }
      }
    }
    .sign-in-component__gender {
      min-height: 40px;
    }
    .address-search-results {
      table {
        tr.address-results-row {
          td {
            letter-spacing: normal;
            word-break: break-all;
            width: 25%;
          }
        }
      }
    }
  }
}

.welcome15-overlay {
  .email_popover {
    .email_popover__content {
      padding-top: 10px;
    }
    .email_popover__social_icons {
      width: 100%;
      bottom: 5px;
      text-align: center;
      a {
        display: inline-block;
      }
      .twitter,
      .pinterest {
        display: none;
      }
    }
  }
}

#lpChat {
  .lp_survey_area {
    .lp_pages_area {
      .lp_radio_button {
        label::before {
          display: none;
        }
      }
    }
  }
}

.page-footer {
  .footer-social-links {
    .line {
      background-image: url('/media/images/global/line-logo-blue.png');
      background-repeat: no-repeat;
      background-size: 24px 24px;
      width: 24px;
      height: 24px;
      margin-#{$ldirection}: 15px;
      margin-top: 2px;
      &:hover {
        background-image: url('/media/images/global/line-logo-white.png');
        background-repeat: no-repeat;
        background-size: 24px 24px;
        width: 24px;
        height: 24px;
        margin-#{$ldirection}: 15px;
        margin-top: 2px;
      }
    }
  }
}

.formatter-mb-slideshow {
  .flexslider {
    .slides > li {
      display: block;
    }
  }
}

.r4_mod5_cats {
  #r4_mod5_cat_1 {
    padding-#{$ldirection}: 7.6%;
  }
  #r4_mod5_cat_2 {
    padding-#{$ldirection}: 7.4%;
  }
  #r4_mod5_cat_3 {
    padding-#{$ldirection}: 17.5%;
  }
  #r4_mod5_cat_4 {
    padding-#{$ldirection}: 17.2%;
  }
}

#colorbox {
  .bisPcIframe {
    height: 255px;
  }
}

.tiny-waitlist-overlay {
  height: 255px;
  #waitlist {
    .email_input {
      input[type='email'] {
        width: 100%;
      }
    }
    .align-r {
      text-align: center;
      padding-top: 10px;
      input[type='submit'] {
        height: 40px;
        line-height: 40px;
        @include swap_direction(padding, 0 20px);
      }
    }
  }
}

.product-full {
  &__price-size {
    .product-full__price-text {
      float: none;
    }
    .product__inventory-status {
      margin-#{$ldirection}: 0;
    }
  }
}

.device-pc {
  #colorbox {
    #cboxClose {
      top: 16px;
    }
  }
}

.page-sticky-footer {
  .page-sticky-footer__left {
    &--sticky {
      span {
        font-size: 20px;
        letter-spacing: 0.05em;
      }
    }
  }
}

.section-pure-color-love {
  .pc_love__yourLooksSlides {
    .pc_love__yourLooksSlideHover {
      &#pc_love__yourLooksSlideHover16,
      &#pc_love__yourLooksSlideHover17,
      &#pc_love__yourLooksSlideHover18 {
        margin-top: 15.6%;
      }
    }
  }
}

.brand-aerin {
  .mpp__product-grid {
    .product-brief {
      &__view-details {
        display: none;
      }
    }
    a {
      &.product_brief {
        &__image-container {
          pointer-events: none;
        }
        &__headers {
          pointer-events: none;
          display: block;
          padding-top: 0px;
        }
      }
    }
  }
  .colorbox__quickshop {
    .quickshop__reviews,
    .quickshop__button-container,
    .quickshop__view-full {
      display: block;
    }
  }
}

.page-navigation {
  .level-1 {
    font-size: 20px;
    letter-spacing: 0.05em;
    &.re-nutriv,
    &.aerin,
    &.best-sellers,
    &.gifts {
      font-size: 14px;
      letter-spacing: 0.05em;
    }
  }
  .Estee.Stories {
    .level-1 {
      font-size: 14px;
      letter-spacing: 0.05em;
    }
  }
  .depth-1 {
    h3 {
      font-size: 22px;
      letter-spacing: 0.05em;
    }
    &.menu-container {
      .level-2 {
        h3 {
          font-size: 22px;
          letter-spacing: 0.05em;
        }
      }
    }
  }
  .level-3 {
    font-size: 20px;
    letter-spacing: 0.05em;
  }
}

.page-utilities {
  &__signin-text {
    font-size: 22px;
    letter-spacing: 0.05em;
  }
}

.page-footer {
  font-size: 20px;
  p {
    font-size: 20px;
  }
  .footer-header,
  .email_signup__header {
    letter-spacing: 0.05em;
  }
  .page-sticky-footer {
    &__left {
      .node-elc-nodeblock {
        .special-offers-drawer {
          a {
            font-weight: normal;
          }
        }
      }
    }
    &__right {
      .menu > li {
        font-size: 22px;
        letter-spacing: 0.05em;
        .promo-messages {
          b {
            font-weight: normal;
          }
        }
      }
    }
    &__mobile__inner {
      .menu > li {
        font-size: 22px;
        letter-spacing: 0.05em;
      }
    }
  }
  .special-offers-tray {
    .offers-email-signup-form,
    .offers-register-form,
    .offers-sign-in-form {
      .headline--offers-tray {
        font-size: 22px;
        letter-spacing: 0.05em;
      }
    }
    .offers-email-signup-form {
      .form-item {
        input {
          font-size: 22px;
          letter-spacing: 0.05em;
        }
      }
      &__sign-in-wrapper {
        p {
          font-size: 22px;
          letter-spacing: 0.05em;
          a {
            font-size: 22px !important;
            letter-spacing: 0.05em;
          }
        }
      }
    }
    .special-offer {
      .special-offer__header {
        font-size: 22px;
        letter-spacing: 0.05em;
      }
      .special-offer__text {
        @include swap_direction(margin, 8px 0 10px);
        letter-spacing: 0.05em;
        span {
          font-size: 22px !important;
        }
      }
      a {
        font-size: 22px;
      }
    }
    .special-offer-benefits {
      @include swap_direction(padding, 20px 30px 0 20px);
      .special-offer-benefits__content {
        font-size: 22px;
        letter-spacing: 0.05em;
        line-height: 1.2em;
        span {
          font-size: 22px !important;
        }
      }
      .special-offer-benefits__link-wrapper a {
        font-size: 22px;
        letter-spacing: 0.05em;
      }
    }
  }
  .footer-legal {
    li {
      &.last,
      a {
        font-size: 18px;
      }
    }
  }
  .country-chooser {
    a.country-chooser__selector {
      font-size: 20px;
      letter-spacing: 0.05em;
    }
  }
  .form-text,
  .form-submit {
    font-size: 20px;
    letter-spacing: 0.05em;
  }
}

.multi_use_homepage_module {
  .multi_use_homepage_v1 {
    .headline_content {
      &__subtext,
      &__explore_link,
      &__disclaimer {
        line-height: 120%;
      }
      &__explore_link a {
        letter-spacing: 0.05em;
        font-size: 22px;
      }
      &__subcopy,
      &__subheader {
        strong {
          letter-spacing: 0.15em;
          font-size: 12px;
        }
      }
      &__subheader {
        font-weight: normal;
      }
    }
    .body_copy {
      letter-spacing: 0.05em;
      font-size: 24px;
    }
  }
}

.olapic-widget {
  &__subhead {
    font-size: 22px;
    letter-spacing: 0.05em;
  }
  &__heading {
    div {
      letter-spacing: 0.05em;
    }
  }
}

.multi_use_mpp_v1 {
  .module_block {
    .body_copy {
      font-size: 20px;
      letter-spacing: 0.05em;
    }
    &.module_block_Aerin {
      .body_copy {
        font-size: 14px;
      }
      .button {
        &.cta {
          &.cta__button {
            white-space: nowrap;
          }
        }
      }
    }
    .headline_content {
      &__subtext,
      &__explore_link,
      &__disclaimer {
        line-height: 120%;
      }
      &__subtext {
        .brand-aerin & {
          line-height: 200%;
        }
      }
      &__headline {
        letter-spacing: -0.05em;
        p {
          letter-spacing: -0.028em;
        }
      }
      &__explore_link,
      &__disclaimer {
        margin-top: 15px;
      }
      &__subheader {
        font-size: 12px;
        letter-spacing: 0.15em;
      }
    }
  }
}

.mpp__header {
  font-size: 44px;
  letter-spacing: 0.025em;
}

a.selectBox {
  font-size: 18px;
  letter-spacing: 0.05em;
}

.selectBox-options {
  li {
    a {
      font-size: 20px;
    }
  }
}

body {
  &.page-product {
    .selectBox-options {
      &.selectBox-dropdown-menu {
        li {
          a {
            font-size: 18px;
          }
        }
      }
    }
  }
}

.product_brief {
  &__misc-flag,
  &__button--shop-now,
  &__button--add-to-bag,
  &__full-details:link,
  &__full-details:visited {
    font-size: 18px;
    letter-spacing: 0.05em;
  }
  &__sub-header {
    letter-spacing: 0.05em;
  }
  &__price,
  &__label2 {
    font-size: 20px;
    letter-spacing: 0.05em;
  }
  &__desc1,
  &__desc2,
  &__price-size {
    font-size: 20px;
  }
  &__reviews-spacer {
    height: auto;
  }
  &__desc1 {
    line-height: 1;
  }
}

.multi_use_tout_v1 {
  .module_block {
    .headline_content__headline {
      letter-spacing: -0.05em;
    }
    .body_copy {
      font-size: 24px;
      letter-spacing: 0.05em;
      line-height: 150%;
      .explore_link__underline {
        font-size: 20px;
      }
    }
    &__img {
      padding-top: 15px;
    }
  }
}

.text-promo {
  font-size: 22px;
  letter-spacing: 0.05em;
}

.Comparison {
  &.Tout {
    .text-promo {
      font-size: 14px;
      @media #{$device-ipad_only} {
        font-size: 1vw;
      }
    }
  }
}

.link--bold {
  font-size: 20px;
  letter-spacing: 0.05em;
}

#main {
  .custom-mpp.mpp-grid {
    .mpp-grid {
      li.el-product {
        .el-product_price {
          font-size: 20px;
          letter-spacing: 0.05em;
        }
      }
    }
    &.renutriv {
      h2 {
        font-size: 35px;
        letter-spacing: 0.025em;
      }
      li {
        .product-header {
          h3,
          h4 {
            letter-spacing: 0.05em;
          }
        }
        a.el-product_shop-now {
          font-size: 18px;
          letter-spacing: 0.05em;
        }
      }
    }
    .mpp-header {
      .product-sort-container {
        .product-sort-button {
          font-size: 18px;
        }
      }
    }
  }
  .extended-mpp {
    .mpp__product {
      .product_brief {
        &__buttons--non-shaded {
          .product_brief {
            &__button--benefits {
              font-size: 18px;
              letter-spacing: 0.05em;
            }
          }
        }
        &__button-panel {
          &.button-color-picker {
            font-size: 18px;
          }
        }
      }
    }
    .mpp__product-grid {
      .product__inventory-status {
        li {
          font-size: 18px;
        }
      }
    }
  }
}

#renutriv.boutique {
  &.mpp {
    section.chat-parallax {
      .text-promo.eyebrow {
        font-size: 22px;
      }
      .chat-parallax__headline {
        letter-spacing: -0.05em;
      }
    }
  }
}

.spp-product__anchor {
  font-size: 20px;
  letter-spacing: 0.05em;
}

.section-product {
  .product-full {
    a.product-full__detail-link,
    &__engraving,
    .free_standard_shipping_returns {
      font-size: 20px;
      letter-spacing: 0.05em;
    }
    &__attribute {
      p,
      h5 {
        font-size: 20px;
        letter-spacing: 0.05em;
      }
    }
    &__price-text {
      font-size: 20px;
      letter-spacing: 0.05em;
    }
    &__price-size-select-container,
    &__shade-select-container {
      .selectBox-dropdown {
        .selectBox-label {
          font-size: 20px;
          letter-spacing: 0.05em;
        }
      }
    }
    &__button-container {
      .selectBox-dropdown {
        .selectBox-label {
          font-size: 18px;
          letter-spacing: 0.05em;
        }
      }
      .button {
        font-size: 18px;
        letter-spacing: 0.05em;
        font-weight: bold;
      }
      .notify_me {
        font-size: 18px;
        letter-spacing: 0.05em;
        line-height: 30px;
      }
    }
    &__wishlist {
      font-size: 18px;
      letter-spacing: 0.05em;
    }
    .review-snippet {
      .pr-snippet {
        .pr-snippet-read-and-write {
          .pr-snippet-review-count,
          .pr-snippet-write-review-link {
            font-size: 18px !important;
            letter-spacing: 0.05em;
          }
        }
      }
    }
    .product__inventory-status {
      li {
        font-size: 18px;
        letter-spacing: 0.05em;
      }
    }
  }
  .spp-product {
    &__details-container {
      .spp-product__details-header {
        font-weight: bold;
        letter-spacing: 0.05em;
      }
      .spp-product__details-description {
        font-size: 20px;
        letter-spacing: 0.05em;
        p {
          font-family: $DBHeavent;
          font-size: 20px;
        }
      }
      .spp-product__details-column {
        .spp-product__details-attribute {
          p,
          li {
            font-family: $DBHeavent;
            font-size: 20px;
            letter-spacing: 0.05em;
          }
        }
      }
    }
    &__details {
      .spp-product__details-attribute {
        &__label {
          font-size: 20px;
          letter-spacing: 0.05em;
        }
      }
    }
    &__mini-bag {
      &-description {
        .spp-product__mini-bag-header,
        .spp-product__mini-bag-sub-header {
          letter-spacing: 0.05em;
        }
        .spp-product__mini-bag-price-size {
          font-size: 16px;
          letter-spacing: 0.02em;
        }
      }
      &-selection {
        .spp-product__mini-bag-quantity,
        .selectBox-label {
          font-size: 20px;
          font-weight: bold;
          letter-spacing: 0.05em;
        }
        &-add-button,
        .button {
          font-size: 18px;
          letter-spacing: 0.05em;
        }
      }
    }
    &__how-to-use {
      .spp__steps {
        .text-promo {
          font-size: 18px;
          letter-spacing: 0.05em;
        }
        .spp_howtouse__product {
          .spp_howtouse__product-details {
            .text--bold {
              font-size: 20px;
              letter-spacing: 0.05em;
            }
          }
          .quickshop__add-button {
            font-size: 20px;
            letter-spacing: 0.05em;
          }
        }
      }
    }
  }
  .discover_more {
    &__product-price {
      font-size: 20px;
      letter-spacing: 0.05em;
    }
    &__product-quickshop {
      font-size: 18px;
      letter-spacing: 0.05em;
    }
  }
}

.brand-renutriv {
  .product-full {
    &__description-section {
      .product_brief__sub-header {
        font-size: 20px;
        letter-spacing: 0.05em;
      }
      .review-snippet {
        .pr-snippet {
          .pr-snippet-read-and-write {
            font-size: 18px;
            letter-spacing: 0.05em;
          }
        }
      }
    }
    #BVBrowserWebkit {
      .BVRRRatingSummaryLinks {
        span {
          font-size: 14px;
          letter-spacing: 0.15em;
        }
      }
    }
    &__attribute {
      h5,
      p {
        font-size: 20px;
        letter-spacing: 0.05em;
      }
    }
  }
  .spp-product {
    &__details-container {
      .spp-product__details-header {
        font-weight: bold;
        letter-spacing: 0.05em;
      }
    }
    &__anchor {
      &--scroll {
        font-size: 14px;
        font-weight: 100;
        letter-spacing: 0.15em;
      }
    }
    &__details {
      &-column01,
      &-column02 {
        .spp-product__details-description {
          font-size: 20px;
          letter-spacing: 0.05em;
        }
        .spp-product__details-attribute {
          h5,
          p,
          li {
            font-size: 20px;
            letter-spacing: 0.05em;
          }
        }
      }
    }
  }
  .video-carousel {
    max-width: 957px;
    margin: 0 auto;
    .slick-carousel {
      .slick-prev {
        left: -50px;
      }
      .slick-next {
        right: -50px;
      }
    }
  }
}

#main .spp__reviews_head {
  .spp-product__reviews {
    .pr-review-snapshot {
      .pr-review-snapshot-block {
        .pr-histogram-label {
          font-size: 20px;
          letter-spacing: 0.05em;
        }
        .pr-h1 {
          font-size: 24px;
          letter-spacing: 0.05em;
        }
        .pr-snapshot-tag-def {
          .pr-filter-btn__buttonText {
            font-size: 26px;
            letter-spacing: 0.02em;
          }
        }
        .pr-review-snapshot-snippets {
          .pr-snippet-reco-to-friend {
            .pr-reco-to-friend-message {
              font-size: 20px;
              letter-spacing: 0.05em;
              line-height: 10px;
            }
          }
        }
        .pr-snippet-read-and-write,
        .pr-rd-no-reviews {
          .pr-snippet-write-review-link {
            font-size: 20px !important;
            letter-spacing: 0.05em;
          }
        }
      }
    }
    .pr-multiselect {
      .pr-caret-icon {
        bottom: 16px;
      }
    }
    .pr-review-display {
      .pr-rd-main-header {
        .pr-rd-sort {
          font-size: 20px;
          letter-spacing: 0.05em;
          line-height: 13px;
        }
        .pr-rd-review-total {
          clear: both;
        }
        .pr-rd-review-header-contents {
          .pr-multiselect-button {
            @include swap_direction(margin, 5px 7px 5px 0);
            span {
              font-size: 20px;
              letter-spacing: 0.05em;
            }
          }
          .pr-multiselect-options {
            label {
              span {
                font-size: 20px;
                letter-spacing: 0.05em;
              }
            }
          }
        }
      }
      .pr-rd-main-footer {
        .pr-rd-content-block {
          .pr-rd-to-top {
            font-size: 20px;
            letter-spacing: 0.05em;
          }
          .pr-rd-review-position {
            span {
              font-size: 20px;
              letter-spacing: 0.05em;
            }
          }
          .pr-rd-pagination-btn {
            font-size: 20px;
            letter-spacing: 0.05em;
          }
        }
      }
      .pr-rd-search-reviews-input {
        input {
          font-size: 20px;
          letter-spacing: 0.05em;
        }
      }
    }
    .pr-review {
      .pr-rd-header {
        .pr-h2 {
          font-size: 20px;
          letter-spacing: 0.05em;
        }
      }
      .pr-accordion {
        .pr-accordion-btn {
          span {
            font-size: 20px;
            letter-spacing: 0.05em;
          }
        }
        .pr-accordion-content {
          dl {
            dd,
            dt {
              font-size: 20px;
              letter-spacing: 0.05em;
            }
          }
        }
      }
      .pr-rd-footer {
        .pr-rd-bottomline {
          span {
            font-size: 20px;
            letter-spacing: 0.05em;
          }
        }
        .pr-rd-flag-review-container {
          a {
            font-size: 20px;
            letter-spacing: 0.05em;
          }
        }
        .pr-helpful-yes,
        .pr-helpful-no {
          .pr-helpful-count,
          .pr-helpful-count:before {
            font-size: 20px;
            letter-spacing: 0.05em;
          }
        }
      }
      .pr-rd-description {
        font-size: 20px;
        letter-spacing: 0.05em;
        .pr-rd-right {
          .pr-rd-reviewer-details {
            span,
            time {
              font-size: 20px;
              letter-spacing: 0.05em;
            }
          }
        }
        .pr-rd-description-text {
          font-size: 20px;
          letter-spacing: 0.05em;
          .pr-rd-author-location {
            font-size: 20px;
            letter-spacing: 0.05em;
          }
        }
      }
    }
    .pr-rd-no-reviews {
      .pr-snippet-write-review-link {
        font-size: 20px;
        letter-spacing: 0.05em;
      }
    }
  }
}

.brand-aerin {
  #main {
    .mpp--brand-aerin {
      .product_brief__buttons-container {
        .button__quickshop,
        .product-brief__view-details {
          font-size: 18px;
          letter-spacing: 0.05em;
        }
      }
    }
    .spp__reviews_head {
      .spp-product__reviews {
        .pr-review-display {
          .pr-rd-main-header {
            .pr-rd-sort {
              line-height: 14px;
            }
          }
        }
      }
    }
    .spp-product {
      &__details {
        .spp-product {
          &__details-header {
            font-size: 30px;
          }
          &__details-description {
            p {
              font-size: 20px;
            }
          }
        }
      }
    }
    .woa-blog__content {
      font-size: 22px;
      .basic-textarea-v1 {
        p {
          font-size: 22px;
        }
      }
    }
  }
  .selectBox-dropdown-menu {
    &.selectBox-options {
      &.quickshop__price-size-select {
        &-selectBox-dropdown-menu {
          li {
            a {
              font-size: 20px;
              letter-spacing: 0.05em;
            }
          }
        }
      }
    }
  }
  &.section-world-aerin {
    .page-main {
      p {
        font-size: 17px;
      }
    }
  }
}

.colorbox__quickshop {
  #cboxWrapper {
    .quickshop {
      .quickshop__tab-container {
        .quickshop__tab,
        &.quickshop__tab--description,
        &.ellipsis {
          letter-spacing: 0.05em;
        }
      }
      .quickshop__description-section {
        .quickshop__header,
        .quickshop__sub-header {
          letter-spacing: 0.05em;
        }
        .quickshop__reviews {
          .quickshop__reviews-count,
          .quickshop__review-write {
            font-size: 18px;
            letter-spacing: 0.05em;
          }
        }
      }
      .quickshop__button-container {
        .quickshop__quantity {
          .selectBox-label {
            font-size: 18px;
            letter-spacing: 0.05em;
          }
        }
        .quickshop__add-button {
          font-size: 18px;
          letter-spacing: 0.05em;
        }
      }
      .quickshop__personal {
        .quickshop__wishlist,
        .quickshop__view-full {
          font-size: 18px;
          letter-spacing: 0.05em;
        }
      }
    }
  }
}

.section-esteestories {
  .es-landing {
    &__filters,
    &__sub-filters {
      li {
        font-size: 24px;
      }
    }
    &__filters__side__wrapper {
      font-size: 20px;
      letter-spacing: 0.05em;
      .es-landing__filter-control {
        @include swap_direction(padding, 0 12px);
      }
      .filter-trigger {
        a:after {
          background-position: -427px -6850px;
        }
      }
    }
  }
  .ee-landing__text {
    h4 {
      letter-spacing: -0.05em;
    }
  }
  .text--bold,
  .text--short {
    font-size: 24px;
    letter-spacing: 0.05em;
  }
  .ee-subhead {
    font-size: 24px;
  }
}

#estee-stories-article {
  .es_content {
    h2 {
      letter-spacing: 0.05em;
      &.es_header-text_subtitle {
        letter-spacing: -0.05em;
      }
    }
    .back-bar__link {
      .text {
        letter-spacing: 0.05em;
      }
    }
    .es_header-tags {
      font-size: 24px;
      letter-spacing: 0.05em;
    }
    .basic-textarea-v1 {
      p {
        font-size: 22px;
      }
    }
    .text-product-listing-names-sub {
      letter-spacing: 0.05em;
    }
    .text--bold {
      font-size: 20px;
      letter-spacing: 0.05em;
    }
    .ee-article-products__list {
      .cta,
      .sku-brief-editorial .link--bold {
        font-size: 18px;
        letter-spacing: 0.05em;
      }
    }
    .ee-disco-more__item {
      .text--short {
        font-size: 24px;
        letter-spacing: 0.05em;
      }
    }
    .headline--tertiary {
      letter-spacing: 0.05em;
    }
  }
}

.section-customer-service {
  .sidebar-page {
    &__sidebar {
      ul {
        &.menu {
          li {
            a {
              font-size: 17px;
              letter-spacing: 0.05em;
            }
          }
        }
      }
    }
    &__content {
      .sidebar-page__content-title {
        font-size: 90px;
      }
      .cs-quick-info {
        &__title {
          font-size: 20px;
          letter-spacing: 0.05em;
        }
        &__block {
          &--live-chat {
            span {
              font-size: 20px;
            }
            .cs__livechat {
              display: none;
            }
            .cs-quick-info__title--mini {
              background-position: 0 -1783px;
            }
          }
        }
      }
      .basic-textarea-v1 {
        h2 {
          font-size: 50px;
          letter-spacing: -0.05em;
        }
        h5 {
          font-size: 30px;
          letter-spacing: -0.05em;
        }
        div,
        p,
        li {
          font-size: 22px;
        }
        p {
          strong {
            font-size: 22px;
            letter-spacing: 0.05em;
          }
        }
      }
    }
  }
  .customer-service-landing-page {
    .sidebar-page {
      &__content {
        .cs-quick-info {
          &__block {
            &--live-chat {
              .cs__livechat {
                display: block;
              }
            }
          }
        }
      }
    }
  }
}

.section-foundation-finder {
  .ff-quiz {
    &__edit-header {
      .ff-quiz__start-over-link {
        font-size: 17px;
        letter-spacing: 0.05em;
      }
    }
    &__header {
      .ff-quiz__nav-text {
        span {
          font-size: 19px;
          letter-spacing: 0.05em;
        }
      }
    }
    &__carousel-wrapper {
      .ff-quiz__question {
        .ff-quiz__headline--tertiary {
          font-size: 17px;
          letter-spacing: 0.05em;
        }
        .ff-quiz__description {
          font-size: 20px;
          letter-spacing: 0.05em;
          line-height: 5px;
        }
      }
    }
  }
  .ff-results {
    &__links {
      .link--bold {
        font-size: 12px;
      }
    }
    &__matches-copy {
      p {
        font-size: 20px;
        letter-spacing: 0.05em;
      }
    }
    &__recommended-products {
      .sku-brief-editorial__size-price-reviews {
        .text--bold {
          font-size: 20px;
          letter-spacing: 0.05em;
          font-weight: bold;
        }
      }
      .sku-brief-editorial__button-wrapper {
        .button--dark-secondary {
          font-size: 18px;
          letter-spacing: 0.05em;
        }
      }
    }
  }
  .spp-product__how-to-use {
    #spp-howtouse-headline {
      font-size: 20px;
      letter-spacing: 0.05em;
    }
    .spp-howtouse {
      .spp_howtouse__product-details {
        .text--bold {
          font-size: 20px;
          letter-spacing: 0.05em;
        }
      }
      .quickshop__add-button {
        font-size: 18px;
        letter-spacing: 0.05em;
      }
    }
  }
}

.section-promotions {
  .special-offer-row-promo-wrapper {
    .special-offer-row-promo {
      .special-offer-promo-text-wrapper {
        .special-offer-headline {
          font-size: 55px;
        }
      }
    }
  }
  .special-offer-row-wrapper {
    .special-offer-text-wrapper {
      .special-offer-headline {
        letter-spacing: -0.05em;
      }
      .special-offer-eyebrow {
        letter-spacing: 0.05em;
      }
      .special-offer-copy-block {
        p {
          font-size: 22px;
          &.under-cta {
            font-size: 17px;
          }
        }
      }
    }
  }
  .promo-unit {
    .promo-unit__headline {
      font-size: 40px;
      letter-spacing: -0.05em;
    }
    .promo-unit__copy,
    .promo-unit__subheader {
      font-size: 22px;
      letter-spacing: 0.05em;
    }
  }
}

.spp-product__how-to-use {
  .spp-howtouse {
    .spp_howtouse__product-details {
      .text--bold {
        font-size: 20px;
        letter-spacing: 0.05em;
      }
    }
    .quickshop__add-button {
      font-size: 18px;
      letter-spacing: 0.05em;
    }
  }
}

.lp_survey_area {
  .lp_question_wrapper {
    .lp_radiobutton_wrapper {
      input[type='radio'] ~ label {
        &::after {
          background: none;
          box-shadow: none;
          border: none;
        }
      }
    }
  }
}
.zero-dollar {
  &-buttons {
    display: flex;
    padding-top: 40px;
    .button {
      margin: 0px auto;
      color: $color-white;
      background-color: $color-black;
    }
  }
  &-success,
  &-error {
    text-align: center;
    font-size: 28px;
    padding-top: 60px;
  }
  &-content {
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    flex-direction: column;
    flex: 1;
    max-width: 60%;
    text-align: $ldirection;
    justify-content: center;
    align-items: flex-start;
    padding-#{$ldirection}: 30px;
  }
}

.user-login-state {
  .user-greeting {
    font-size: 18px;
  }
  .user-logged-in a {
    font-size: 18px;
  }
}

.question {
  li {
    min-height: auto !important;
  }
}

.page-node {
  .page-main {
    margin-top: 82px;
    .hero-block-wrapper {
      margin-top: 82px;
    }
    .multi_use_homepage_formatter,
    .mpp {
      margin-top: 0;
    }
  }
  &.section-best-sellers-makeup {
    .page-main {
      margin-top: 82px;
    }
  }
  &.section-makeup {
    .multi_use_mpp_v1 {
      &.mpp_module_wrapper {
        margin-top: -82px;
      }
    }
  }
  &.section-foundation-finder,
  &.section-aerin,
  &.section-world-aerin,
  &.section-whats-new-aerin {
    .page-main {
      margin-top: 0;
    }
    .multi_use_mpp_v1 {
      &.mpp_module_wrapper {
        margin-top: 0;
      }
    }
  }
}

.page-product {
  .page-main {
    .product-full {
      &__image-container {
        margin-top: 82px;
      }
    }
  }
}
