.el_boutique_wrapper {
  .el_boutique_body {
    p {
      font-family: DB Hevetica x;
      font-size: 18px;
    }
  }
}

section#section-4 {
  .chat-parallax__content {
    .chat-parallax__subtitle {
      span {
        font: 12px/18px 'DB Hevetica x';
      }
    }
  }
}

#section-3 {
  .title-wrapper {
    ul {
      li {
        .tile-text {
          p {
            font: 12px/18px 'DB Hevetica x';
          }
        }
      }
    }
  }
  .three-tile-block {
    .button-container {
      a {
        font-family: DB Hevetica x;
        font-size: 18px;
      }
    }
  }
}

.glam-it-up-your-look-article-late-night-party-page,
.glam-it-up-your-look-article-bare-faced-beauty-page,
.glam-it-up-your-look-article-beauty-at-work-page {
  .column-main {
    #estee-stories-article {
      .es_header-text-group {
        .es_header-text {
          h2 {
            font-family: DB Hevetica x;
            font-size: 30px;
          }
        }
      }
    }
  }
}

.column-main {
  #estee-stories-article {
    .sku-brief-editorial {
      .text-product-listing-names-link {
        .text-product-listing-names-main {
          font-family: OptimaDisplayLight;
          font-size: 21px;
        }
        .text-product-listing-names-sub {
          font-family: OptimaDisplayLight;
          font-size: 16px;
        }
      }
    }
    .es_content {
      .basic-textarea-v1:first-of-type {
        p {
          font-family: DB Hevetica x;
        }
      }
    }
    .es_header-text-group {
      .es_header-text {
        h2 {
          font-family: DB Hevetica x;
          font-size: 22px;
        }
      }
    }
  }
}

.three-tile-block {
  .section-wrapper {
    ul {
      li {
        .button-container {
          a {
            font-family: DB Hevetica x;
          }
        }
      }
    }
  }
}
