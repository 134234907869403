.book-appt-container {
  .registration__privacy-policy-list {
    padding-left: 22px;
  }
}

#appointments {
  #booking-step3 {
    .confirm-container {
      .total-time {
        margin-right: 10px;
      }
      .start-over {
        display: block;
        clear: both;
      }
    }
    .book-appt-container {
      input[type='text'],
      input[type='tel'],
      input[type='email'] {
        color: $color-black;
      }
    }
  }
}

.appt-book {
  .appt-book-sections-container {
    .location-select-section-content {
      font-size: 18px;
      &__title {
        font-size: 115px;
      }
      &__subhead,
      &__description,
      &__msg {
        font-size: 16px;
      }
    }
    .appt-book-location-selector-copy {
      font-size: 16px;
    }
    .appointment-select {
      .appointments-container {
        #change-services-link {
          font-size: 14px;
        }
        .selects-container {
          .select-container {
            h4 {
              font-size: 14px;
            }
          }
          .location-select {
            font-size: 16px;
          }
        }
        .calendar-head {
          .cal-head-content {
            .cal-copy {
              font-size: 14px;
            }
          }
        }
        table {
          th,
          td {
            font-size: 16px;
          }
        }
        .confirm-container {
          .appt-book-book-submit {
            font-size: 20px;
          }
        }
      }
    }
  }
  .appt-book-content-header {
    .section-header {
      &__inner,
      &--description {
        font-size: 14px;
      }
    }
  }
  .services-container {
    .service-details {
      .selection-bar {
        .selection-selector {
          font-size: 14px;
        }
      }
    }
  }
  .confirmation {
    .confirmation-container > div {
      h4 {
        font-size: 18px;
      }
      span {
        font-size: 20px;
      }
    }
    .appt-book-content-header {
      p {
        font-size: 18px;
      }
    }
    .confirmation-container {
      .location {
        a {
          font-size: 16px;
        }
      }
    }
  }
}

.appt-book-overlay {
  &.appt-book-datepicker-overlay {
    .overlay-content-container {
      .pika-label {
        font-family: $optimalight;
      }
    }
  }
  .overlay-content-container {
    .overlay-content {
      margin: -15px 0px 0px 0px;
      h4 {
        font-size: 28px;
      }
    }
  }
}

.appt-book-book-countermax-overlay {
  .overlay-content-container {
    p {
      margin: 0;
    }
  }
}
