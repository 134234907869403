#loyalty_registration_form {
  .sign-in-component {
    &__address-wrapper {
      .address_form_container {
        label {
          display: block;
        }
        .ship_type_container {
          label {
            display: inline-block;
          }
        }
      }
    }
  }
}
